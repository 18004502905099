import React, { useCallback } from "react";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Product } from "../../../services/uuno-api/products";
import { AutostartInstructionsDetails } from "../../AutostartInstructions";
import { useNavigate } from "react-router-dom";
import { Station } from "../../../services/uuno-api/stations";
import {
  capturePayment,
  CapturePaymentRequest,
  createOrder,
  CreateOrderRequest
} from "../../../services/web-api/paypal";
import { licensePlateIsValid } from "../../../util/license-plate";

export interface PayPalButtonProps {
  station: Station;
  product: Product;
  licensePlate: string | undefined;
}

const PayPalButton: React.FC<PayPalButtonProps> = ({station, product, licensePlate}) => {
  const navigate = useNavigate();

  const goToInstructions = useCallback(() => {
    navigate("/instructions", { state: { station, licensePlate } as AutostartInstructionsDetails });
  }, [navigate, station, licensePlate]);

  return (
    <PayPalScriptProvider
      options={{
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID!,
        locale: "de_DE",
        currency: "EUR",
        intent: "capture"
      }
    }>
      <PayPalButtons
        forceReRender={[licensePlate]}
        style={{
          shape: "rect",
          color: "blue",
          layout: "vertical",
          label: "paypal"
        }}
        fundingSource={"paypal"}
        onClick={async (data, actions) => {
          if (!licensePlateIsValid(licensePlate)) {
            return await actions.reject();
          }
        }}
        createOrder={async (data, actions) => {
          const request: CreateOrderRequest = {
            stationId: station.id,
            productId: product.id,
          };

          const result = await createOrder(request);
          return result.orderId;
        }}
        onApprove={async (data, actions) => {
          const request: CapturePaymentRequest = {
            orderId: data.orderID,
            licensePlate: licensePlate!
          };

          await capturePayment(request);
          goToInstructions();
        }}
      />
    </PayPalScriptProvider>
  );
}

export default PayPalButton;
