import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useStripe, PaymentRequestButtonElement } from "@stripe/react-stripe-js";
import { PaymentRequest, StripePaymentRequestButtonElementOptions } from "@stripe/stripe-js";
import { PaymentRequestPaymentMethodEvent } from "@stripe/stripe-js/types/stripe-js/payment-request";
import { createPaymentIntent, CreatePaymentIntentRequest } from '../../../../services/web-api/stripe';
import { Station } from "../../../../services/uuno-api/stations";
import { Product } from "../../../../services/uuno-api/products";
import { AutostartInstructionsDetails } from "../../../AutostartInstructions";
import { useNavigate } from "react-router-dom";
import { licensePlateIsValid } from "../../../../util/license-plate";

interface CheckoutFormProps {
  station: Station;
  product: Product;
  licensePlate: string | undefined;
}

const usePaymentRequest = (station: Station, product: Product, licensePlate: string | undefined) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();
  const [canMakePayment, setCanMakePayment] = useState(false);

  const navigate = useNavigate();

  const goToInstructions = useCallback(() => {
    navigate("/instructions", { state: { station, licensePlate } as AutostartInstructionsDetails });
  }, [navigate, station, licensePlate]);

  const onPaymentMethod = useCallback(async (event: PaymentRequestPaymentMethodEvent) => {
    console.log("[PaymentMethod]", event.paymentMethod);
    console.log("[Email]", event.payerEmail);

    // TODO: Error handling
    if (!licensePlate) {
      // TODO show error!!
      return;
    }

    const createPaymentIntentRequest: CreatePaymentIntentRequest = {
      stationId: station.id,
      productId: product.id,
      licensePlate: licensePlate,
      email: event.payerEmail!
    }
    
    try {
      const response = await createPaymentIntent(createPaymentIntentRequest);
      const clientSecret = response.clientSecret;

      // Confirm the PaymentIntent without handling potential next actions (yet).
      const { paymentIntent, error: confirmError } = await stripe?.confirmCardPayment(
        clientSecret,
        { payment_method: event.paymentMethod.id },
        { handleActions: false }
      )!;

      if (confirmError) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        event.complete("fail");
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        event.complete("success");
        // Check if the PaymentIntent requires any actions and if so let Stripe.js
        // handle the flow. If using an API version older than "2019-02-11"
        // instead check for: `paymentIntent.status === "requires_source_action"`.
        if (paymentIntent.status === "requires_action") {
          // Let Stripe.js handle the rest of the payment flow.
          const { error } = await stripe?.confirmCardPayment(clientSecret)!;
          if (error) {
            event.complete("fail");
          } 
        }
      }
    } catch (error) {
      event.complete("fail");
    }

    // Set card payment to complete
    event.complete("success");

    goToInstructions();
  }, [station, product, licensePlate, stripe, goToInstructions]);

  useEffect(() => {
    if (stripe && !paymentRequest) {
      const pr = stripe.paymentRequest({
        country: "DE",
        currency: "eur",
        total: {
          label: "Demo total",
          amount: product.price
        },
        requestPayerEmail: true
      });
      setPaymentRequest(pr);
    }
  }, [product, stripe, paymentRequest]);

  useEffect(() => {
    let subscribed = true;
    if (paymentRequest) {
      paymentRequest.canMakePayment().then((res) => {
        if (res && subscribed) {
          setCanMakePayment(true);
        }
      });
    }

    return () => {
      subscribed = false;
    };
  }, [paymentRequest]);

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.on("paymentmethod", onPaymentMethod);
    }
    return () => {
      if (paymentRequest) {
        paymentRequest.off("paymentmethod", onPaymentMethod);
      }
    };
  }, [paymentRequest, onPaymentMethod]);

  return canMakePayment ? paymentRequest : null;
};

const CheckoutForm: React.FC<CheckoutFormProps> = ({station, product, licensePlate}) => {
  const paymentRequest = usePaymentRequest(station, product, licensePlate);

  const options = useMemo(
    () =>
      ({
        paymentRequest,
        style: {
          paymentRequestButton: {
            theme: "light-outline",
            height: "48px",
            type: "default"
          }
        }
      } as StripePaymentRequestButtonElementOptions),
    [paymentRequest]
  );

  if (!paymentRequest) {
    return null;
  }

  return (
    <PaymentRequestButtonElement
      className="PaymentRequestButton"
      options={options}
      onReady={() => {
        console.log("PaymentRequestButton [ready]");
      }}
      onClick={(event) => {
        // Check that we have a license plate
        if (!licensePlateIsValid(licensePlate)) {
          event.preventDefault();
        }
        console.log("PaymentRequestButton [click]");
      }}
      onBlur={() => {
        console.log("PaymentRequestButton [blur]");
      }}
      onFocus={() => {
        console.log("PaymentRequestButton [focus]");
      }}
    />
  );
};

export default CheckoutForm;
