import Lottie from "lottie-react";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { WashCodeDetails } from '../index';
import rolloverStopAnimation from "../../../assets/animations/rollover_stop.json";
import getOutPushButtonAnimation from "../../../assets/animations/get_out_push_button.json";
import getOutPushButton2Animation from "../../../assets/animations/get_out_push_button_2.json";

import styles from "./Instructions.module.css";
import Button from "../../../components/Button";

const Instructions: React.FC = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const washCodeDetails = state as WashCodeDetails;
  const [step, setStep] = useState(0);

  const nextStep = () => {
    setStep(step + 1);
  };

  const goBack = () => {
    navigate(-1);
  };

  const rewind = () => {
    setStep(0);
  };

  return (
    <div className={styles.container}>
      <div className={styles.washCodeContainer}>
        <h2 className={styles.washCodeTitle}>Dein waschcode:</h2>
        <h3 className={styles.washCode}>{washCodeDetails.washCode}</h3>
      </div>
      {step === 0 && (
        <>
          <h1 className={styles.title}>Fahre in die Anlage bis STOP aufleuchtet</h1>
          <div className={styles.animationContainer}>
            <Lottie
              className={styles.animation}
              animationData={rolloverStopAnimation}
              loop={false}
              autoPlay={true}
              onComplete={nextStep}
            />
          </div>
        </>
      )}

      {step === 1 && (
        <>
          <h1 className={styles.title}>
            Bitte steige aus und gib den 8-stelligen Code ins Terminal ein
          </h1>
          <div className={styles.animationContainer}>
            <Lottie
              className={styles.animation}
              animationData={getOutPushButtonAnimation}
              loop={false}
              autoPlay={true}
              onComplete={nextStep}
            />
          </div>
        </>
      )}

      {step === 2 && (
        <>
          <h1 className={styles.title}>
            So einfach
            <br />
            geht's
          </h1>
          <button className={styles.rewindButton} onClick={rewind} />
          <div className={styles.animationContainer}>
            <Lottie
              className={styles.animation}
              animationData={getOutPushButton2Animation}
              loop={true}
              autoPlay={true}
            />
          </div>
        </>
      )}

      <Button label="Zurück" onClick={goBack} />
    </div>
  );
};

export default Instructions;
