import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import styles from "./AutostartInstructions.module.css";
import { Station } from "../../services/uuno-api/stations";
import Lottie from "lottie-react";
import recognitionIntroAnimation from "../../assets/animations/recognition_intro.json";
import recognitionLoopAnimation from "../../assets/animations/recognition_loop.json";

export interface AutostartInstructionsDetails {
  station: Station;
  licensePlate: string;
}

const AutostartInstructions: React.FC = () => {
  const { state } = useLocation();
  const data = state as AutostartInstructionsDetails;
  const station = data.station;
  const [introFinished, setIntroFinished] = useState(false)

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Wenn die Waschstraße<br/>frei ist, fahre vor bis zum Kundenschalter</h1>
      <div className={styles.stationInfo}>
        <img className={styles.logo} src={station.brand.iconUrl} alt={"Logo"}/>
        <div className={styles.address}>
          <b>{station.name}</b><br/>
          {station.street}, {station.zip} {station.city}
        </div>
      </div>
      <div className={styles.animationContainer}>
        {!introFinished && (
          <Lottie
            className={styles.animation}
            animationData={recognitionIntroAnimation}
            loop={false}
            autoPlay={true}
            onComplete={() => setIntroFinished(true)}
          />
        )}

        {introFinished && (
          <Lottie
            className={styles.animation}
            animationData={recognitionLoopAnimation}
            loop={true}
            autoPlay={true}
          />
        )}
      </div>
      <p className={styles.info}>Beachte die betreiberangaben</p>
    </div>
  );
};

export default AutostartInstructions;
