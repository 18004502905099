import client from "./base";

export interface StationBrand {
  iconUrl: string;
}

export interface Station {
  id: number;
  name: string;
  street: string;
  city: string;
  zip: string;
  mphSiteId?: string;
  superOperatorId?: number;
  brand: StationBrand
}

export const getStation = async (stationId: number) => {
  const response = await client.get<Station>(`/car-wash-stations/${stationId}`);
  return response.data;
};
