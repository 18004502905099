import client from "./base";

export interface CustomerDetails {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  payerId: string;
  email: string;
}

export interface PurchaseRequest {
  stationId: number;
  productId: number;
  nonce: string;
  customerDetails: CustomerDetails;
  riskCorrelationId: any;
}

export interface PurchaseResponse {
  washCode: string;
  expirationDate?: string;
  email: string;
}

export const purchaseWashCode = async (request: PurchaseRequest) => {
  const response = await client.post<PurchaseResponse>("/wash-codes/purchase", request, {
    headers: {
      "Content-Type": "application/json"
    }
  });
  return response.data;
};
