import client from './base';

export interface CreatePaymentIntentRequest {
  stationId: number;
  productId: number;
  licensePlate: string;
  email: string;
}

export interface CreatePaymentIntentResponse {
  clientSecret: string;
}

export const createPaymentIntent = async (request: CreatePaymentIntentRequest) => {
  const response = await client.post<CreatePaymentIntentResponse>(
    "/stripe/create-payment-intent", 
    request);
  return response.data;
};
