import React from "react";
import Braintree from "braintree-web";
import { PurchaseRequest, purchaseWashCode } from "../../../services/uuno-api/wash-codes";
import { useNavigate } from "react-router-dom";
import { BraintreePayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";

interface HemPayPalButtonProps {
  stationId: number;
  productId: number;
}

const HemPayPalButton: React.FC<HemPayPalButtonProps> = ({ stationId, productId }) => {
  const navigate = useNavigate();

  const getDeviceData = async () => {
    const clientInstance = await Braintree.client.create({
      authorization: process.env.REACT_APP_MPH_PAYPAL_TOKEN!
    });

    // Create the data collector to get the riskCorrelationId
    const dataCollectorInstance = await Braintree.dataCollector.create({
      client: clientInstance
    });

    const deviceData = dataCollectorInstance?.rawDeviceData as any;
    await dataCollectorInstance.teardown();
    await clientInstance.teardown(() => {});
    return deviceData;
  };

  return (
    <PayPalScriptProvider
      options={{
        "client-id": process.env.REACT_APP_MPH_PAYPAL_CLIENT_ID!,
        "data-user-id-token": process.env.REACT_APP_MPH_PAYPAL_TOKEN!,
        locale: "de_DE",
        currency: "EUR",
        intent: "tokenize",
        vault: "true"
      }}
    >
      <BraintreePayPalButtons
        style={{
          shape: "pill",
          color: "blue",
          layout: "vertical",
          label: "paypal"
        }}
        fundingSource="paypal"
        createBillingAgreement={function (data, actions) {
          return actions.braintree.createPayment({
            flow: "vault"
          });
        }}
        onApprove={async function (data, actions) {
          const payload = await actions.braintree.tokenizePayment(data);
          const details = payload.details;
          const deviceData = await getDeviceData();

          const request: PurchaseRequest = {
            stationId: stationId,
            productId: productId,
            nonce: payload.nonce,
            customerDetails: {
              firstName: details.firstName,
              lastName: details.lastName,
              email: details.email,
              phoneNumber: "",
              payerId: details.payerId
            },
            riskCorrelationId: deviceData?.correlation_id
          };

          console.log(request);

          // Submit to server
          const response = await purchaseWashCode(request);

          console.log(response);

          // Go to wash code screen
          navigate("/wash-code", { state: response });
        }}
        onCancel={function () {
          console.log("PayPal payment canceled");
        }}
        onError={function (error) {
          console.error("PayPal error", error);
        }}
      />
    </PayPalScriptProvider>
  );
};

export default HemPayPalButton;
