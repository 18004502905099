import client from './base';

export interface CreateOrderRequest {
  stationId: number;
  productId: number;
}

export interface CreateOrderResponse {
  orderId: string;
}

export interface CapturePaymentRequest {
  orderId: string;
  licensePlate: string;
}

export interface CapturePaymentResponse {
}

export const createOrder = async (request: CreateOrderRequest) => {
  const response = await client.post<CreateOrderResponse>(
    "/paypal/create-order",
    request);
  return response.data;
};

export const capturePayment = async (request: CapturePaymentRequest) => {
  const response = await client.post<CapturePaymentResponse>(
    "/paypal/capture-payment",
    request);
  return response.data;
};
