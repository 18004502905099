import axios from "axios";

export interface ProblemDetails {
  type: string;
  title: string;
  status: boolean;
  detail?: string;
}

const client = axios.create({
  baseURL: process.env.REACT_APP_UUNO_API_URL,
  timeout: 30000
});

export default client;
