import React from "react";
import { Route, Routes } from "react-router-dom";
import Deal from "./pages/Deal";
import WashCode from "./pages/WashCode";
import Instructions from "./pages/WashCode/Instructions";
import Help from "./pages/Help";
import AutostartInstructions from "./pages/AutostartInstructions";

const App: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path="/">
          <Route index element={<Deal />} />
          <Route path="instructions" element={<AutostartInstructions />} />
          <Route path="wash-code" element={<WashCode />} />
          <Route path="wash-code/instructions" element={<Instructions />} />
          <Route path="help" element={<Help />} />
          <Route path="*" element={<Deal />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
